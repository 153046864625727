import React from 'react'
import { Grid, Segment, Header, List, Container, Input, Button, Icon } from 'semantic-ui-react'

const Footer = () => (
    <div>
    <div style={{backgroundColor: '#B71D23'}}>
        <Segment vertical style={{ padding: '5em 0em' }}>
        <Container>
        <Grid divided inverted stackable>
            <Grid.Row>
            <Grid.Column width={4}>
                <Header inverted as='h2' content='Solutions' />
                <List link inverted size='big' >
                <List.Item><a href="/DataFoundation"><p style={{color: '#FFEEEE'}}>Data Foundation</p></a></List.Item>
                <List.Item><a href="/VoiceOfConsumer"><p style={{color: '#FFEEEE'}}>Voice of Consumer</p></a></List.Item>
                <List.Item><a href="/MMMAttribution"><p style={{color: '#FFEEEE'}}>MMM + Attribution</p></a></List.Item>
                <List.Item><a href="/CampaignOptimization"><p style={{color: '#FFEEEE'}}>Campaign Optimization</p></a></List.Item>
                <List.Item><a href="/DecisionSupport"><p style={{color: '#FFEEEE'}}>Decision Support</p></a></List.Item>
                </List>
                    <a target='_blank' rel ='noopener noreferrer' href="https://twitter.com/kvantumleap?lang=en"><Icon inverted name='twitter' size='big' /></a>
                    <a target='_blank' rel ='noopener noreferrer' href="https://www.facebook.com/realtime.marketing.optimization/"><Icon inverted name='facebook' size='big' /></a>
                    <a target='_blank' rel ='noopener noreferrer' href="https://www.linkedin.com/company/2651343/"><Icon inverted name='linkedin' size='big' /></a>
            </Grid.Column>
            <Grid.Column width={4}>
                <Header inverted as='h2' content='Company' />
                <List link inverted size='big'>
                <List.Item><a href="/AboutUs"><p style={{color: '#FFEEEE'}}>About Us</p></a></List.Item>
                <List.Item><a href="/Leadership"><p style={{color: '#FFEEEE'}}>Leadership</p></a></List.Item>
                <List.Item><a href="/Technology"><p style={{color: '#FFEEEE'}}>Technology</p></a></List.Item>
                <List.Item><a href="/KvantumLab"><p style={{color: '#FFEEEE'}}>Kvantum Lab</p></a></List.Item>
                <List.Item><a href="/OurApproach"><p style={{color: '#FFEEEE'}}>Our Approach</p></a></List.Item>
                </List>
            </Grid.Column>
            <Grid.Column width={4}>
                <Header as='h2' inverted content='Insights' />
                <List link inverted size='big'>
                <List.Item><a href="/Resources"><p style={{color: '#FFEEEE'}}>Case Studies</p></a></List.Item>
                </List>
                <Grid.Row>
                <Header as='h3' inverted content='Get insights in your inbox' />
                <div><Input focus placeholder='Email Address'  style={{padding:'0em 0em 0.5em 0em'}} /></div>
                <Button inverted>SIGN UP</Button>
                </Grid.Row>
            </Grid.Column>
            <Grid.Column width={4}>
                <Header inverted as='h2' content='Careers' />
                <List link inverted size='big'>
                <List.Item><a href="/Careers"><p style={{color: '#FFEEEE'}}>Join Our Team</p></a></List.Item>
                </List>
                <Grid.Row>
                <a href="https://blog.kvantuminc.com/contact-us-for-marketing-analytics-solutions/" style={{color:'#000000'}}><Button inverted>TALK TO US</Button></a>
                </Grid.Row>
                <Grid.Row>
                <Header inverted as='h3'> </Header>
                <Header inverted as='h3'>
                    <Grid.Row>Phone Number</Grid.Row>
                    <Grid.Row>+1 844 582 6886</Grid.Row>
                    </Header>
                </Grid.Row>
            </Grid.Column>
            </Grid.Row>
        </Grid>
        </Container>
    </Segment>
  </div>
  <div style={{backgroundColor: '#7F1723'}}>
  <Grid divided inverted stackable>
  <Grid.Row>
            <Grid.Column width={4}>
            <Header inverted as='h4'> <p style={{marginLeft:'10%'}}> 2019 Kvantum, Inc.</p> </Header>    
            </Grid.Column>
    </Grid.Row>
      
    </Grid>
  </div>
  </div>
) 
  
  export default Footer