/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
//import MainMenu from '../MainMenu'
//import Header from "./header"
import styled, {createGlobalStyle} from 'styled-components';
import { Helmet } from 'react-helmet';

import 'semantic-ui-css/semantic.min.css';
// Import additional styles to sow masthead (part of Semanti-UI Layoots)
import './masthead.css'
import ResponsiveContainer from './ResponsiveContainer'
import Footer from './Footer'
import KvantumIcon from '../../images/Kvantum_Icon.png'



const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
  body {
    font-family: 'Open-Sans', sans-serif;
    margin: 0 !important;
    padding: 0 !important;
  }
`
const LayoutWrapper = styled.div`
  max-width: 2420px;
  margin: 0 auto;
`

const Layout = ({ children }) => (
  <ResponsiveContainer>
    <Helmet>
      <link rel="icon" href={KvantumIcon} />
      <title>Kvantum Public Website</title>
    </Helmet>
  <div>
    <GlobalStyles />
    <LayoutWrapper>
    {children}
    </LayoutWrapper>
    <Footer />
  </div>
</ResponsiveContainer>
);

export default Layout



