import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Dropdown,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

//import HomepageHeading from "../../components/semantic/HomepageHeading"
import Logo from "../logo"

//import styled from 'styled-components';
//import SiteInfo from '../SiteInfo';

import 'semantic-ui-css/semantic.min.css';
//import HomepageHeading from './HomePageHeading';

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

  



/* Heads up!
   * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
   * It can be more complicated, but you can create really flexible markup.
   */
  class DesktopContainer extends Component {
    state = {}
  
    hideFixedMenu = () => this.setState({ fixed: false })
    showFixedMenu = () => this.setState({ fixed: true })
    
    render() {
      const { children } = this.props
      const { fixed } = this.state
  
      return (
        <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
          <Visibility
            once={false}
            onBottomPassed={this.showFixedMenu}
            onBottomPassedReverse={this.hideFixedMenu}
          >
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 0, padding: '1em 0em', backgroundColor:'#7F1723' }}
              vertical
            >
              <Menu
                fixed={fixed ? 'top' : null}
                inverted={!fixed}
                pointing={fixed}
                secondary={!fixed}
                size='huge'
                style={{backgroundColor:'#7F1723', color: '#FF0000', fontsize:'3em', fontWeight: 400, padding: '10px, 0px, 0px, 0px'}} 
              >
                
                  <Menu.Item><Logo /></Menu.Item>  
                  <Menu.Menu position='right'>
                    <Menu.Item  position='right'>
                    <a href="https://www.kvantum.ai"><p style={{color:'white'}}>HOME</p></a>
                    </Menu.Item>
                    <Menu.Item position='right'>
                    <a href="https://www.kvantum.ai/OurApproach"><p style={{color:'white'}}>OUR APPROACH</p></a>
                    </Menu.Item>
                    <Dropdown text='SOLUTIONS' pointing item style={{color:'white'}}>
                      <Dropdown.Menu>
                        <Dropdown.Item><a href="https://www.kvantum.ai/DataFoundation" style={{color:'#000000'}}>DATA FOUNDATION</a></Dropdown.Item>
                        <Dropdown.Item><a href="https://www.kvantum.ai/VoiceOfConsumer" style={{color:'#000000'}}>VOICE OF CONSUMER</a></Dropdown.Item>
                        <Dropdown.Item><a href="https://www.kvantum.ai/MMMAttribution" style={{color:'#000000'}}>MMM + ATTRIBUTION</a></Dropdown.Item>
                        <Dropdown.Item><a href="https://www.kvantum.ai/CampaignOptimization" style={{color:'#000000'}}>CAMPAIGN OPTIMIZATION</a></Dropdown.Item>
                        <Dropdown.Item><a href="https://www.kvantum.ai/DecisionSupport" style={{color:'#000000'}}>DECISION SUPPORT</a></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown text='COMPANY' pointing item style={{color:'white'}}>
                      <Dropdown.Menu>
                      <Dropdown.Item><a href="https://www.kvantum.ai/AboutUs" style={{color:'#000000'}}>ABOUT US</a></Dropdown.Item>
                      <Dropdown.Item><a href="https://www.kvantum.ai/Leadership" style={{color:'#000000'}}>LEADERSHIP</a></Dropdown.Item>
                      <Dropdown.Item><a href="https://www.kvantum.ai/Technology" style={{color:'#000000'}}>TECHNOLOGY</a></Dropdown.Item>
                      <Dropdown.Item><a href="https://www.kvantum.ai/KvantumLab" style={{color:'#000000'}}>KVANTUM LAB</a></Dropdown.Item>
                      <Dropdown.Item><a href="https://www.kvantum.ai/contact-us-for-marketing-analytics-solutions/" style={{color:'#000000'}}>TALK TO US</a></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>                    
                    <Menu.Item position='right'>
                    <a href="https://www.kvantum.ai/Resources"><p style={{color:'white'}}>RESOURCES</p></a>
                    </Menu.Item>
                    <Menu.Item position='right'>
                    <a href="https://www.kvantum.ai/Careers"><p style={{color:'white'}}>CAREERS</p></a>
                    </Menu.Item>
                    <Menu.Item position='right' style={{color:'white'}}>
                      <a href="https://blog.kvantuminc.com"><p style={{color:'white'}}>BLOG</p></a>
                    </Menu.Item>
                    <Menu.Item>
                    <a href="/contact_us/"><Button primary>TALK TO US</Button></a>
                    </Menu.Item>
                  </Menu.Menu>
              </Menu>
            </Segment>
          </Visibility>
  
          {children}
        </Responsive>
      )
    }
  }
  
  DesktopContainer.propTypes = {
    children: PropTypes.node,
  }
  
  class MobileContainer extends Component {
    state = {}
  
    handleSidebarHide = () => this.setState({ sidebarOpened: false })
  
    handleToggle = () => this.setState({ sidebarOpened: true })
  
    render() {
      const { children } = this.props
      const { sidebarOpened } = this.state
  
      return (
        <div style={{backgroundColor:'#7F1723', color: '#FF0000'}}>
        <Responsive
          as={Sidebar.Pushable}
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
          inverted='true'
          style={{backgroundColor:'#7F1723', color: '#FF0000'}}
        >
          <Sidebar.Pusher dimmed={sidebarOpened}  style={{backgroundColor:'#7F1723'}}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 5, margin:'0px', backgroundColor:'#7F1723', color: '#FF0000' }}
              vertical  
            >
              <Container style={{backgroundColor:'#7F1723'}}>
                <Menu inverted secondary size='large'  >
                  <Menu.Item as='a' position='left' onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                    <Logo />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
  
            {children}
          </Sidebar.Pusher>

          <Sidebar
            as={Menu}
            animation='push'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            style={{backgroundColor:'#7F1723', color: '#FF0000'}}
          >
             <Menu.Item><Logo /></Menu.Item>  
                  <Menu.Menu position='right' style={{backgroundColor:'#7F1723'}}>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/"><p style={{color:'white'}}>HOME</p></a>
                    </Menu.Item>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/OurApproach"><p style={{color:'white'}}>OUR APPROACH</p></a>
                    </Menu.Item>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}> <p style={{color:'white'}}>SOLUTIONS</p>
                      <Menu.Item style={{backgroundColor:'#7F1723'}}>
                          <a href="https://www.kvantum.ai/DataFoundation"><p style={{color:'white'}}>DATA FOUNDATION</p></a>
                        </Menu.Item>
                        <Menu.Item style={{backgroundColor:'#7F1723'}}>
                          <a href="https://www.kvantum.ai/VoiceOfConsumer"><p style={{color:'white'}}>VOICE OF CONSUMER</p></a>
                        </Menu.Item>
                        <Menu.Item style={{backgroundColor:'#7F1723'}}>
                          <a href="https://www.kvantum.ai/MMMAttribution"><p style={{color:'white'}}>MMM + ATTRIBUTION</p></a>
                        </Menu.Item>
                        <Menu.Item style={{backgroundColor:'#7F1723'}}>
                          <a href="https://www.kvantum.ai/CampaignOptimization"><p style={{color:'white'}}>CAMPAIGN OPTIMIZATION</p></a>
                        </Menu.Item>
                        <Menu.Item style={{backgroundColor:'#7F1723'}}>
                          <a href="https://www.kvantum.ai/DecisionSupport"><p style={{color:'white'}}>DECISION SUPPORT</p></a>
                        </Menu.Item>
                    </Menu.Item>
                    <Menu.Item  style={{backgroundColor:'#7F1723'}}> <p style={{color:'white'}}>COMPANY</p>
                      <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/AboutUs"><p style={{color:'white'}}>ABOUT US</p></a>
                      </Menu.Item>
                      <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/Leadership"><p style={{color:'white'}}>LEADERSHIP</p></a>
                      </Menu.Item>
                      <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/Technology"><p style={{color:'white'}}>TECHNOLOGY</p></a>
                      </Menu.Item>
                      <Menu.Item style={{backgroundColor:'#7F1723'}}>
                        <a href="https://www.kvantum.ai/KvantumLab"><p style={{color:'white'}}>KVANTUM LAB</p></a>
                      </Menu.Item>
                    </Menu.Item>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}>
                    <a href="https://www.kvantum.ai/Resources"><p style={{color:'white'}}>RESOURCES</p></a>
                    </Menu.Item>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}>
                    <a href="https://www.kvantum.ai/Careers"><p style={{color:'white'}}>CAREERS</p></a>
                    </Menu.Item>
                    <Menu.Item position='right' style={{backgroundColor:'#7F1723'}}>
                      <a href="https://blog.kvantuminc.com"><p style={{color:'white'}}>BLOG</p></a>
                    </Menu.Item>
                    <Menu.Item style={{backgroundColor:'#7F1723'}}>
                    <a href="/contact_us/"><Button primary>TALK TO US</Button></a>
                    </Menu.Item>
                  </Menu.Menu>
          </Sidebar>

        </Responsive>
        </div>
      )
    }
  }
  
  MobileContainer.propTypes = {
    children: PropTypes.node,
  }
  
  const ResponsiveContainer = ({ children }) => (
    <div>
      <DesktopContainer>{children}</DesktopContainer>
      <MobileContainer>{children}</MobileContainer>
    </div>
  )
  
  ResponsiveContainer.propTypes = {
    children: PropTypes.node,
  }
  
  
  export default ResponsiveContainer