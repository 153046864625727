import React from 'react';
import styled from 'styled-components'
import { Image } from 'semantic-ui-react'
import logo_image from '../images/Kvantum_logo-white.png'


const LogoDiv = styled.div`
  margin-left: 0.5rem;
`

const Logo = () => 
(
  <LogoDiv>
  <Image src = {logo_image} alt="Kvantum Logo" size='small'/>
  </LogoDiv>

)

export default Logo;


/* <LogoImage src = {props.allWordpressWpLogo.edges[0].node.url.source_url} alt="Kvantum Logo"/> */